import { defineComponent } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import {
	debounce,
	type WatchCallback,
	type WatcherFunction,
} from '@/javascript/vuejs/utils/globalUtils'
import { appendRandomString } from '@/javascript/vuejs/utils/stringUtils'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'input': (value: string): boolean => true,
	'update': (): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'InputSearch',

	props: {
		...on(emits),

		additionalClasses: { default: '', type: String },
		additionalInputClasses: { default: '', type: String },
		dataTest: { default: '', type: String },
		labelText: { default: '', type: String },
		placeholderText: { default: 'Search', type: String },
		value: { optional: true, type: String },
	},

	emits,

	data(): Data {
		return {
			inputModel: this.value || '',
			isFocused: false,
			unwatch: null,
		}
	},

	created() {
		const debouncedSearchString = debounce(() => { this.handleInput() }, 1000) as WatchCallback

		this.unwatch = this.$watch('inputModel', debouncedSearchString)
	},

	beforeDestroy() {
		this.unwatch?.()
	},

	methods: {
		handleBlur() {
			this.isFocused = false
		},

		handleEscape(): void {
			this.inputModel = ''
			this.handleInput()
		},

		handleFocus() {
			this.isFocused = true
		},

		handleInput(): void {
			this.$emit('input', this.inputModel.trim())
			this.$emit('update')
		},

		handleKeypress(key: KeyboardEvent): undefined | void {
			if (key) {
				this.$nextTick(() => {
					if (key.key === 'Escape') {
						this.handleEscape()
					}
				})
			} else {
				this.handleInput()
			}
		},
	},

	render() {
		return (
			<div class={[
				'wide-input',
				'temporary-input-fix',
				this.additionalClasses,
			]}>
				{this.labelText && <p><b>{this.labelText}</b></p>}

				<i class="wide-input__icon fa fa-search temporary-input-fix__icon" aria-hidden="true" />
				<input
					v-model={this.inputModel}
					autocomplete={appendRandomString()}
					class={['wide-input__input temporary-input-fix__input', this.additionalInputClasses]}
					data-test={this.dataTest}
					placeholder={this.placeholderText}
					readonly={!this.isFocused}
					type="text"
					onBlur={this.handleBlur}
					onFocus={this.handleFocus}
					onKeydown={this.handleKeypress}
				/>
			</div>
		)
	},
})

interface Data {
	inputModel: string
	isFocused: boolean
	unwatch: WatcherFunction | null
}
